.TrackInput {
  position: relative;
  // background: #f00;

  &__tracks {
    position: absolute;
    background: #fff;
    width: 100%;
    // width: 50%;
    // left: 50%;
    display: none;
    z-index: 10000;
    display: block;
  }

  &__input {
    input {
      display: flex;
      width: 100%;
    }
  }

  &__track {
    position: relative;
  }

  .TrackInput__input:focus-within, .TrackInput__input.open:focus-within {
    // outline: 10px solid #f00;

    .TrackInput__tracks {
      display: block;
    }
  }

  &__remove {
    // position: absolute;
    // top: 1rem;
    // right: 1rem;
    svg {
      width: 3.5rem;
      height: 3.5rem;
      // height: 3.5rem;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
    line-height: 0.1;
    margin: 0 0 0 2rem;
    // background: #00f;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  audio {
    width: 100%;
  }
  // li {
  //   // opacity: 0.1 !important;
  //   white-space: nowrap;
  //   display: flex;
  //   align-items: center;
  //   gap: 2.8rem;

  //   > div {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 0.8rem;
  //   }

  //   h3 {
  //     font-size: 2rem;
  //     line-height: 2rem;
  //   }
  //   time {
  //     font-size: 1.6rem;
  //     line-height: 2rem;
  //     color: #849aa4;
  //   }
  // }

  .Track {
    // all: unset;
    border: 0;
    background: none;
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    text-align: left;

    width: 100%;
    font-size: 2rem;
    line-height: 1.2;
    gap: 2rem;
    padding: 1rem;

    transition: background-color 0.1s;

    > div {
      grid-column: 1 span 1;
    }

    h3 {
      font-size: 2rem;
      line-height: 2rem;
    }
    span {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #849aa4;
    }

    &:is(button) {
      cursor: pointer;

      &:hover {
        background: #ddd;
        text-decoration: none;
      }
    }
  }

  // button.Track {
  //   cursor: pointer;
  //   &:hover {
  //     background: #ddd;
  //     text-decoration: none;
  //   }
  // }
}
