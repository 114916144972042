.VideoFloor {
  position: relative;
  background: var(--clr-dark);
  color: var(--clr-light);

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  video,
  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  video {
    object-fit: cover;
    z-index: -3;
  }

  .content {
    position: relative;
    // box-shadow: 0 0 2rem #f00;
  }

  &::before {
    background: radial-gradient(
      79.57% 82.52% at 76.11% 20.43%,
      rgba(20, 20, 20, 0) 0%,
      rgba(20, 20, 20, 0.2) 75.27%
    );
    background-blend-mode: multiply;
  }

  .content {
    isolation: isolate;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    // inset: -2.4rem;
    // padding: 10rem;
    // box-shadow: 0 0 10rem 10px #141414;
    // background: #141414;
    // background-blend-mode: multiply;
    // // opacity: .3;
    // z-index: -1;
    opacity: 1;
    background: linear-gradient(0, #141414 30%,rgba(20, 20, 20, 0) 100%);
  }
}
