.Floor {
  position: relative;
  z-index: 1;
  width: 100%;
  // min-height: 100vh;
  // padding: 4.8rem 2.4rem;
  padding: 4.8rem max(2.4rem, 50vw - (48rem / 2));
  background: linear-gradient(360deg, #FFFFFF 0%, #F2F2F2 80.31%);

  &.flow
  // ,
  // form 
  {
    > * + * {
      margin-top: 3.6rem;
    }
  }


}
