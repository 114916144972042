.Faces {
  // aspect-ratio: 616 / 370;

  display: flex;
  position: relative;
  width: 61.6rem * 0.5;
  max-width: 50rem;
  width: 90vw;
  flex-direction: column;
  filter: drop-shadow(0 0.3rem 1rem #0002);

  &__wrapper {
    height: 0;
    padding-top: 60%;

    position: relative;
    width: 61.6rem * 0.5;
    max-width: 50rem;
    width: 90vw;
  }

  .face {
    position: absolute;
    top: 0;
    height: 100%;

    &--gerard {
      left: 0;
    }
    &--anna {
      right: 0;
    }
  }

  svg {
    // display: none;
    // position: absolute;
    // width: 100%;
    // top: 105%;
  }
}
