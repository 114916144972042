.Schedule {
  --gliph-size: 7.8rem;
  position: relative;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  background: linear-gradient(
      to bottom,
      #849aa400 calc(var(--gliph-size) / 2),
      #849aa4ff calc(var(--gliph-size) / 2),
      #849aa4ff calc(100% - (var(--gliph-size) / 2)),
      #849aa400 calc(100% - (var(--gliph-size) / 2))
    )
    no-repeat calc((var(--gliph-size) / 2) - 0.15rem) top / 0.3rem 100%;

  li {
    // opacity: 0.1 !important;
    white-space: nowrap;
    display: flex;
    align-items: center;
    gap: 2.8rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 2rem;
    }
    time {
      font-size: 1.6rem;
      line-height: 2rem;
      color: #849aa4;
    }
  }

  .glyph {
    border-radius: 50%;
    box-shadow: 0.51rem 0.11rem 1.31rem #e1e1e1, 0 0 0 0.4rem #e1e1e1;
    align-self: flex-start;
    flex: 0 0 var(--gliph-size);
    height: var(--gliph-size);
    background: #fff;
    transform: translate3d(0, 0, 0);
  }
}

.ScheduleFloor {
}
