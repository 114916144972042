@font-face {
  font-family: "ITC Souvenir";
  src: url("../assets/fonts/ITC Souvenir Medium.woff2");
  font-weight: 400;
}

:root {
  ///
  /// Tokens

  // Colors
  --clr-dark: #141414;
  --clr-light: #fff;

  // Font family
  --ff-base: "Inter", sans-serif;
  --ff-accent: "ITC Souvenir", serif;

  // Font size
  --fs-body: 1.8rem;
  --lh-body: 2.5rem;

  // Font weight
  --fw-regular: 400;
  --fw-bold: 600;

  // z-index
  --z-layout: 100;
  --z-modal: 1000;
  --z-debug: 10000;

  ///
  ///
  /// configs
  --duration-hover: 300ms;

  ///
  ///
  /// Layout
  --header-height: 5.8rem;
  --flow-space: 2.4rem;
  --container-width: min(90%, 96rem);
  --container-inline: max(5vw, 50vw - 48rem);
  --container-block: max(10vh, 12.8rem);

  // spacing
  --section-block-space: clamp(2rem, 5vh, 5rem);
  --section-inline-space: clamp(2rem, 5vw, 5rem);
  --section-space: var(--section-block-space) var(--section-inline-space);

  ///
  ///
  /// Defaults
  --ff: var(--ff-base);
  --fw: var(--fw-regular);
  --fs: var(--fs-body);
  --lh: var(--lh-body);
}
