.Footer {
  background: var(--clr-dark);
  color: var(--clr-light);

  font-size: 1.4rem;
  line-height: 2.5rem;
  text-align: center;

  &__head {
    padding-bottom: 4.8rem;
  }

  &__info {
    padding: 2.4rem 0;
    border-top: 1px solid;
    border-bottom: 1px solid;

    display: flex;
    flex-wrap: wrap;
    gap: 1.2rem;
  }

  &__foot {
    margin-top: 4.8rem;
  }
}

.card {
  display: flex;
  gap: 1.2rem;
  text-align: left;
  white-space: nowrap;

  h4 {
    font-size: 1.8rem;
  }

  img {
    height: 8rem;
  }
  span {
    display: inline-flex;
    width: 1.5em;
    // margin-right: .5em;
  }
}
