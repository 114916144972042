.Route {
  --width: 500;
  --height: 560;
  --sensor-size: 54.79;
  --sensor-offset: 15;

  // background: #000;
  position: relative;
  display: flex;
  aspect-ratio: var(--width) / var(--height);
  height: 0;
  padding-top: calc(100% * var(--height) / var(--width));

  svg {
    position: absolute;
    inset: 0;
  }

  li {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;

    width: calc(100% * var(--sensor-size) / var(--width));
    height: calc(100% * var(--sensor-size) / var(--height));
    left: calc(100% * var(--left) / var(--width));
    top: calc(100% * var(--top) / var(--height));
    transform: translate(-50%, -50%);

    color: #fff;

    span {
      position: absolute;
      display: block;
      white-space: nowrap;
      padding: 1.5rem;

      padding: calc(100% * var(--sensor-offset) / var(--height))
        calc(100% * var(--sensor-offset) / var(--width));

      font-family: var(--ff-accent);
      font-weight: var(--fw-accent);
      font-size: 1.8rem;
      line-height: 1;
      color: #fff;
    }

    // transform: translate(-50%, -50%) scale(0);
    transition: transform 0.5s;

    &::before,
    &::after {
      content: "";
      display: block;

      border-radius: 50%;
      border: 5px solid;
      width: 100%;
      height: 100%;
      position: absolute;
    }

    &::before {
      width: 50%;
      height: 50%;
    }

    &:nth-child(1) {
      --left: 193.675;
      --top: 527.815;

      span {
        left: 120%;
      }
    }

    &:nth-child(2) {
      --left: 150.945;
      --top: 338.235;

      span {
        right: 120%;
      }
    }

    &:nth-child(3) {
      --left: 317.515;
      --top: 307.555;

      span {
        left: 120%;
      }
    }

    &:nth-child(4) {
      --left: 303.265;
      --top: 176.045;

      span {
        right: 120%;
      }
    }

    &:nth-child(5) {
      --left: 304.355;
      --top: 71.945;
      color: #c52b21;

      span {
        bottom: 120%;
      }
    }
  }
}
