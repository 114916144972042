.IntroFloor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;

  box-shadow: 0 0 5rem #14141444;
  z-index: 2;

  video {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 2;
    opacity: 0.5;
    mix-blend-mode: screen;
  }

  .Floor {
    position: absolute;
    background: #fff;
    inset: 0;

    padding-top: 0;
    padding-bottom: 0;
    // clip-path: circle(10%);
    transition: clip-path 1s;
    clip-path: circle(0% at 50% 45vh);
    z-index: 1;

    .Faces__wrapper {
      margin-top: 1rem;
    }
  }
  .top,
  .bot,
  .mid {
    height: 30vh;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: #0f0;
  }
  .mid {
    position: absolute;
    height: 40vh;
    // background: #f00;
    width: 100vw;
    left: 0;
    top: 50%;
    margin-top: -25vh;
  }

  .top {
    height: 25vh;
    // margin-top: 40vh;
    align-items: flex-end;
  }
  .bot {
    height: 25vh;
    margin-top: 40vh;
    align-items: flex-start;
  }
  // &:hover

  .Faces {
    // position: absolute;
    // top: 50vh;
    // height: 50;

    transform: scale(0.7);
    z-index: 3;
    transition: transform 1s;

    // body.loaded & {
    //   transition: none;
    // }
  }

  // &:not(.loaded) {
  //   .Faces {
  //     transform: scale(0.9);
  //   }
  //   .Floor {
  //     clip-path: circle(10%);
  //   }
  // }
}
