@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

.FormFloor {
  .fieldset-head {
    display: flex;
    align-items: center;
    gap: 0.6rem;

    legend {
      flex-grow: 1;
    }

    .btn {
      --size: 4rem;
      height: var(--size);
      width: var(--size);

      padding: 0 0 0.2rem;
      font-size: calc(var(--size) / 2);
    }
  }

  .list-item {
    display: flex;
    align-items: center;

    font-family: var(--ff-accent);
    font-size: 1.8rem;
    line-height: 2.8rem;
    gap: 1.2rem;

    span {
      padding: 0.8rem 0 1rem;
    }
  }

  // &__splash {
  //   height: 100vh;
  //   background: #f00;
  // }

  // form {
  //   --transition-duration: 0.3s;
  //   transition: opacity var(--transition-duration);

  //   .btn-submit {
  //     margin: 0 auto;
  //     position: relative;

  //     transition: all var(--transition-duration)
  //     cubic-bezier(0.68, -0.55, 0.265, 1.55);

  //     svg,
  //     span {
  //       transition: all var(--transition-duration) ease-in-out;
  //     }

  //     --loader-duration: 1.75s;

  //     svg {
  //       animation: rotate calc(var(--loader-duration) * 7 / 3) linear infinite;
  //       position: absolute;
  //       height: 70%;
  //       opacity: 0;
  //       // transition: all 0.3s;
  //     }

  //     svg circle {
  //       stroke-dasharray: 1, 200;
  //       stroke-dashoffset: 0;
  //       animation: dash var(--loader-duration) ease-in-out infinite;
  //       stroke-linecap: round;
  //       stroke: #fff;
  //     }

      
  //   }

  //   fieldset {
  //     transition: all 0.3s;
  //   }

  //   &.disabled {
  //     pointer-events: none;
  //     opacity: 0.8;
  //     // height: 80vh;
  //     fieldset {
  //       // transform: translateX(-100%);
  //       opacity: 0.6;
  //     }

  //     .btn-submit {
  //       width: 6.4rem;
  //       border-radius: 9in;
  //       // position: absolute;
  //       // top: 50%;
  //       // left: 50%;

  //       svg {
  //         opacity: 1;
  //       }
  //       span {
  //         opacity: 0;
  //       }
  //     }
  //   }
  // }
}
