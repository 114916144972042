.Form {
  position: relative;
  padding-bottom: 6.4rem + 3.6rem;

  --transition-duration: 0.3s;

  &__body {
    > * + * {
      margin-top: 3.6rem;
    }
  }

  &__foot {
    position: absolute;
    width: 100%;
    top: calc(100% - 3.2rem);
    margin-top: -3.2rem;
    overflow: visible;
  }

  &__anchor {
    position: absolute;
    top: -10rem;
  }

  overflow-y: hidden;

  &__splah {
    position: absolute;
    // background: #fff;
    inset: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 2.4rem;
    text-align: center;

    opacity: 0;
    transition: opacity var(--transition-duration);
    pointer-events: none;

    &.active {
      opacity: 1;
    }
  }

  .btn-submit {
    margin: 0 auto;
    position: relative;

    transition: all var(--transition-duration)
      cubic-bezier(0.68, -0.55, 0.265, 1.55);

    svg,
    span {
      transition: all var(--transition-duration) ease-in-out;
    }

    --loader-duration: 1.75s;

    svg {
      animation: rotate calc(var(--loader-duration) * 7 / 3) linear infinite;
      position: absolute;
      height: 70%;
      opacity: 0;
      // transition: all 0.3s;
    }

    svg circle {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
      animation: dash var(--loader-duration) ease-in-out infinite;
      stroke-linecap: round;
      stroke: #fff;
    }
  }

  &.submitting,
  &.submitted {
    // max-height: 50rem;
    // padding-bottom: 0;

    // .Form__foot {
    //   bottom: 50%;
    // }

    .btn-submit {
      width: 6.4rem;
      border-radius: 9in;
      // position: absolute;
      // top: 50%;
      // left: 50%;

      svg {
        opacity: 1;
      }
      span {
        opacity: 0;
      }
    }
  }

  &.submitted {
    .btn-submit {
      opacity: 0;
    }
  }
}
