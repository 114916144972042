.DateFloor {
  &__phone {
    width: 50%;
    margin: 0 auto;
    // outline: 10px solid #0f0;
    // &::before {
    //   content: "";
    //   position: absolute;
    //   --size: 25rem;
    //   width: var(--size);
    //   height: var(--size);
    //   background: #f00;
    //   top:50%;
    //   left:50%;
    //   margin: -7rem 0 0 -12.5rem;
    //   border-radius: 50%;
    //   background: #000;
    //   opacity: .05;
    //   // filter: blur(1rem);
    // }
  }

  &__decs {
    position: absolute;
    // background: #f00;
    inset: 0;
    overflow: hidden;
    pointer-events: none;
    
    > div {
      position: absolute;
      width: var(--size);
      height: var(--size);
      margin-top: calc(var(--size) * -0.5);
      margin-left: calc(var(--size) * -0.5);
      border-radius: 50%;
      // background: #000;
      border: 20rem solid #000;
      border: 2px solid #000;
      opacity: 0.05;
      filter: blur(1px);
      z-index: -1;

      &:nth-child(1) {
        // display:none;
        left: 0%;
        top: 20%;

        opacity: 0.02;
        --size: 600px;
      }

      &:nth-child(2) {
        // display:none;
        left: 140%;
        top: 50%;
        opacity: 0.02;
        --size: 450px;
      }

      &:nth-child(3) {
        // display:none;
        left: 35%;
        top: 90%;
        opacity: 0.03;
        --size: 350px;
      }

      &:nth-child(4) {
        // display:none;
        left: 50%;
        top: 55%;
        opacity: 0.04;
        --size: 25rem;
      }
    }
  }
}

.Iphone {
  --width: 423;
  --height: 858;

  position: relative;
  // aspect-ratio: var(--width) / var(--height);
  height: 0;
  padding-top: calc(var(--height) / var(--width) * 100%);
  // padding-top: 100%;
  // width: 41rem;
  width: 100%;

  background: url("./iphone.png") center / cover;
  filter: drop-shadow(0 0.3rem 1rem #0004);

  video {
    position: absolute;
    top: 0;
    margin-top: calc(100% * 19 / var(--width));
    margin-left: calc(100% * 22 / var(--width));
    width: calc(100% * 380 / var(--width));
    // display: none;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: inherit;
    z-index: 1;
  }
}
