/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
* {
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern" 1;
  font-kerning: normal;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  font-family: sans-serif, Helvetica, Arial;
}
html {
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: transparent;
}
body {
  width: 100%;
  position: relative;
  overflow-x: hidden;
}
img {
  display: block;
  height: auto;
  max-width: 100%;
}
b,
strong {
  font-weight: var(--fw-bold, 700);
}
a {
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
input,
select {
  vertical-align: middle;
}
@font-face {
  font-family: "ITC Souvenir";
  src: url("ITC Souvenir Medium.cd251ac8.woff2");
  font-weight: 400;
}
:root {
  --clr-dark: #141414;
  --clr-light: #fff;
  --ff-base: "Inter", sans-serif;
  --ff-accent: "ITC Souvenir", serif;
  --fs-body: 1.8rem;
  --lh-body: 2.5rem;
  --fw-regular: 400;
  --fw-bold: 600;
  --z-layout: 100;
  --z-modal: 1000;
  --z-debug: 10000;
  --duration-hover: 300ms;
  --header-height: 5.8rem;
  --flow-space: 2.4rem;
  --container-width: min(90%, 96rem);
  --container-inline: max(5vw, 50vw - 48rem);
  --container-block: max(10vh, 12.8rem);
  --section-block-space: clamp(2rem, 5vh, 5rem);
  --section-inline-space: clamp(2rem, 5vw, 5rem);
  --section-space: var(--section-block-space) var(--section-inline-space);
  --ff: var(--ff-base);
  --fw: var(--fw-regular);
  --fs: var(--fs-body);
  --lh: var(--lh-body);
}
html {
  font-size: 62.5%;
  scroll-padding-top: 15rem;
}
body {
  height: auto;
  min-height: 100%;
  overflow-x: hidden;
  font-family: var(--ff);
  font-size: var(--fs);
  line-height: var(--lh);
  font-weight: var(--fw);
}
body.no-scroll, body:not(.loaded) {
  overflow: hidden;
}
body,
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg);
}
a {
  color: inherit;
  text-decoration: none;
}
a:hover, a:focus-visible, a[aria-current] {
  text-decoration: underline;
}
b,
strong {
  font-weight: var(--fw-bold);
}
h1,
h2,
h3,
h4,
legend {
  font-family: var(--ff-accent);
}
h1 {
  font-size: 6rem;
  line-height: 6rem;
}
h2 {
  font-size: 6rem;
  line-height: 6rem;
}
h3,
legend {
  font-size: 2.8rem;
  line-height: 3.3rem;
}
h4 {
  font-size: 2rem;
  line-height: 2.4rem;
}
.btn {
  --bg-hover: #161616;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
  font-family: var(--ff-accent);
  font-size: 2.4rem;
  line-height: 2.2rem;
  padding: 2rem 2.4rem 2.2rem;
  color: var(--clr, #fff);
  background: var(--bg, #333);
  border: 0;
  transition: color var(--duration-hover), background-color var(--duration-hover);
  cursor: pointer;
}
.btn:not([disabled]):hover {
  --bg: var(--bg-hover);
  text-decoration: none;
}
.btn--alt {
  border: 1px solid;
  background: var(--clr, #fff);
  color: var(--bg, #333);
}
.btn[disabled] {
  opacity: 0.3;
  cursor: default;
}
legend {
  margin-bottom: var(2.4rem);
}
input,
textarea {
  padding: 0.8rem 0;
  border: 0;
  background: none;
  border-bottom: 0.2rem solid #bdbdbd;
  border-radius: 0;
  width: 100%;
  font-family: var(--ff-accent);
  font-size: 1.8rem;
  line-height: 2.8rem;
  transition: border-color 0.3s;
}
input:focus,
textarea:focus {
  box-shadow: none;
  outline: none;
  border-color: #141414;
}
textarea {
  resize: none;
  height: 10.2rem;
  transition: height 0.3s;
}
textarea:placeholder-shown:not(:focus) {
  height: 4.6rem;
}
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
  opacity: 1;
  /* Firefox */
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
}
.flow > * + *,
form > * + *,
fieldset > * + * {
  margin-top: var(--flow-space, 2.4rem);
}
form {
  --flow-space: 3.6rem;
}
fieldset {
  --flow-space: 1.2rem;
}
pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;
  white-space: pre-wrap;
}
.Floor {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 4.8rem max(2.4rem, 50vw - 24rem);
  background: linear-gradient(360deg, #FFFFFF 0%, #F2F2F2 80.31%);
}
.Floor.flow > * + * {
  margin-top: 3.6rem;
}
.IntroFloor {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  box-shadow: 0 0 5rem #14141444;
  z-index: 2;
}
.IntroFloor video {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 2;
  opacity: 0.5;
  mix-blend-mode: screen;
}
.IntroFloor .Floor {
  position: absolute;
  background: #fff;
  inset: 0;
  padding-top: 0;
  padding-bottom: 0;
  transition: clip-path 1s;
  clip-path: circle(0% at 50% 45vh);
  z-index: 1;
}
.IntroFloor .Floor .Faces__wrapper {
  margin-top: 1rem;
}
.IntroFloor .top,
.IntroFloor .bot,
.IntroFloor .mid {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.IntroFloor .mid {
  position: absolute;
  height: 40vh;
  width: 100vw;
  left: 0;
  top: 50%;
  margin-top: -25vh;
}
.IntroFloor .top {
  height: 25vh;
  align-items: flex-end;
}
.IntroFloor .bot {
  height: 25vh;
  margin-top: 40vh;
  align-items: flex-start;
}
.IntroFloor .Faces {
  transform: scale(0.7);
  z-index: 3;
  transition: transform 1s;
}
.Faces {
  display: flex;
  position: relative;
  width: 30.8rem;
  max-width: 50rem;
  width: 90vw;
  flex-direction: column;
  filter: drop-shadow(0 0.3rem 1rem #0002);
}
.Faces__wrapper {
  height: 0;
  padding-top: 60%;
  position: relative;
  width: 30.8rem;
  max-width: 50rem;
  width: 90vw;
}
.Faces .face {
  position: absolute;
  top: 0;
  height: 100%;
}
.Faces .face--gerard {
  left: 0;
}
.Faces .face--anna {
  right: 0;
}
.VideoFloor {
  position: relative;
  background: var(--clr-dark);
  color: var(--clr-light);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
.VideoFloor video, .VideoFloor::before, .VideoFloor::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.VideoFloor video {
  object-fit: cover;
  z-index: -3;
}
.VideoFloor .content {
  position: relative;
}
.VideoFloor::before {
  background: radial-gradient(79.57% 82.52% at 76.11% 20.43%, rgba(20, 20, 20, 0) 0%, rgba(20, 20, 20, 0.2) 75.27%);
  background-blend-mode: multiply;
}
.VideoFloor .content {
  isolation: isolate;
}
.VideoFloor::after {
  content: "";
  display: block;
  position: absolute;
  opacity: 1;
  background: linear-gradient(0, #141414 30%, rgba(20, 20, 20, 0) 100%);
}
.LocationFloor {
  background: var(--clr-dark);
  color: var(--clr-light);
  isolation: isolate;
  padding-top: 70vw;
  position: relative;
}
.LocationFloor::before {
  -webkit-transform: translate3d(0, 0, 0);
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  z-index: -1;
  background: linear-gradient(rgba(20, 20, 20, 0) 50vw, #141414 120vw, #2f2f2f);
}
.LocationFloor .video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120vw;
  object-fit: cover;
  z-index: -2;
  overflow: hidden;
}
.LocationFloor .video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.LocationFloor .btn {
  --clr: var(--clr-dark);
  --bg: var(--clr-light);
  --bg-hover: var(--clr-light);
}
.LocationFloor .Route {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem;
}
.Route {
  --width: 500;
  --height: 560;
  --sensor-size: 54.79;
  --sensor-offset: 15;
  position: relative;
  display: flex;
  aspect-ratio: var(--width)/var(--height);
  height: 0;
  padding-top: calc(100% * var(--height) / var(--width));
}
.Route svg {
  position: absolute;
  inset: 0;
}
.Route li {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% * var(--sensor-size) / var(--width));
  height: calc(100% * var(--sensor-size) / var(--height));
  left: calc(100% * var(--left) / var(--width));
  top: calc(100% * var(--top) / var(--height));
  transform: translate(-50%, -50%);
  color: #fff;
  transition: transform 0.5s;
}
.Route li span {
  position: absolute;
  display: block;
  white-space: nowrap;
  padding: 1.5rem;
  padding: calc(100% * var(--sensor-offset) / var(--height)) calc(100% * var(--sensor-offset) / var(--width));
  font-family: var(--ff-accent);
  font-weight: var(--fw-accent);
  font-size: 1.8rem;
  line-height: 1;
  color: #fff;
}
.Route li::before, .Route li::after {
  content: "";
  display: block;
  border-radius: 50%;
  border: 5px solid;
  width: 100%;
  height: 100%;
  position: absolute;
}
.Route li::before {
  width: 50%;
  height: 50%;
}
.Route li:nth-child(1) {
  --left: 193.675;
  --top: 527.815;
}
.Route li:nth-child(1) span {
  left: 120%;
}
.Route li:nth-child(2) {
  --left: 150.945;
  --top: 338.235;
}
.Route li:nth-child(2) span {
  right: 120%;
}
.Route li:nth-child(3) {
  --left: 317.515;
  --top: 307.555;
}
.Route li:nth-child(3) span {
  left: 120%;
}
.Route li:nth-child(4) {
  --left: 303.265;
  --top: 176.045;
}
.Route li:nth-child(4) span {
  right: 120%;
}
.Route li:nth-child(5) {
  --left: 304.355;
  --top: 71.945;
  color: #c52b21;
}
.Route li:nth-child(5) span {
  bottom: 120%;
}
.DateFloor__phone {
  width: 50%;
  margin: 0 auto;
}
.DateFloor__decs {
  position: absolute;
  inset: 0;
  overflow: hidden;
  pointer-events: none;
}
.DateFloor__decs > div {
  position: absolute;
  width: var(--size);
  height: var(--size);
  margin-top: calc(var(--size) * -0.5);
  margin-left: calc(var(--size) * -0.5);
  border-radius: 50%;
  border: 20rem solid #000;
  border: 2px solid #000;
  opacity: 0.05;
  filter: blur(1px);
  z-index: -1;
}
.DateFloor__decs > div:nth-child(1) {
  left: 0%;
  top: 20%;
  opacity: 0.02;
  --size: 600px;
}
.DateFloor__decs > div:nth-child(2) {
  left: 140%;
  top: 50%;
  opacity: 0.02;
  --size: 450px;
}
.DateFloor__decs > div:nth-child(3) {
  left: 35%;
  top: 90%;
  opacity: 0.03;
  --size: 350px;
}
.DateFloor__decs > div:nth-child(4) {
  left: 50%;
  top: 55%;
  opacity: 0.04;
  --size: 25rem;
}
.Iphone {
  --width: 423;
  --height: 858;
  position: relative;
  height: 0;
  padding-top: calc(var(--height) / var(--width) * 100%);
  width: 100%;
  background: url("iphone.e490fbb2.png") center/cover;
  filter: drop-shadow(0 0.3rem 1rem #0004);
}
.Iphone video {
  position: absolute;
  top: 0;
  margin-top: calc(1900% / var(--width));
  margin-left: calc(2200% / var(--width));
  width: calc(38000% / var(--width));
}
.Iphone::after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  background: inherit;
  z-index: 1;
}
.Schedule {
  --gliph-size: 7.8rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  background: linear-gradient(to bottom, #849aa400 calc(var(--gliph-size) / 2), #849aa4ff calc(var(--gliph-size) / 2), #849aa4ff calc(100% - var(--gliph-size) / 2), #849aa400 calc(100% - var(--gliph-size) / 2)) no-repeat calc(var(--gliph-size) / 2 - 0.15rem) top/0.3rem 100%;
}
.Schedule li {
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 2.8rem;
}
.Schedule li > div {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.Schedule li h3 {
  font-size: 2rem;
  line-height: 2rem;
}
.Schedule li time {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #849aa4;
}
.Schedule .glyph {
  border-radius: 50%;
  box-shadow: 0.51rem 0.11rem 1.31rem #e1e1e1, 0 0 0 0.4rem #e1e1e1;
  align-self: flex-start;
  flex: 0 0 var(--gliph-size);
  height: var(--gliph-size);
  background: #fff;
  transform: translate3d(0, 0, 0);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
.FormFloor .fieldset-head {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}
.FormFloor .fieldset-head legend {
  flex-grow: 1;
}
.FormFloor .fieldset-head .btn {
  --size: 4rem;
  height: var(--size);
  width: var(--size);
  padding: 0 0 0.2rem;
  font-size: calc(var(--size) / 2);
}
.FormFloor .list-item {
  display: flex;
  align-items: center;
  font-family: var(--ff-accent);
  font-size: 1.8rem;
  line-height: 2.8rem;
  gap: 1.2rem;
}
.FormFloor .list-item span {
  padding: 0.8rem 0 1rem;
}
.TrackInput {
  position: relative;
}
.TrackInput__tracks {
  position: absolute;
  background: #fff;
  width: 100%;
  display: none;
  z-index: 10000;
  display: block;
}
.TrackInput__input input {
  display: flex;
  width: 100%;
}
.TrackInput__track {
  position: relative;
}
.TrackInput .TrackInput__input:focus-within .TrackInput__tracks, .TrackInput .TrackInput__input.open:focus-within .TrackInput__tracks {
  display: block;
}
.TrackInput__remove {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  line-height: 0.1;
  margin: 0 0 0 2rem;
  cursor: pointer;
}
.TrackInput__remove svg {
  width: 3.5rem;
  height: 3.5rem;
}
.TrackInput__remove:hover {
  text-decoration: none;
}
.TrackInput audio {
  width: 100%;
}
.TrackInput .Track {
  border: 0;
  background: none;
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  text-align: left;
  width: 100%;
  font-size: 2rem;
  line-height: 1.2;
  gap: 2rem;
  padding: 1rem;
  transition: background-color 0.1s;
}
.TrackInput .Track > div {
  grid-column: 1 span 1;
}
.TrackInput .Track h3 {
  font-size: 2rem;
  line-height: 2rem;
}
.TrackInput .Track span {
  font-size: 1.6rem;
  line-height: 2rem;
  color: #849aa4;
}
.TrackInput .Track:is(button) {
  cursor: pointer;
}
.TrackInput .Track:is(button):hover {
  background: #ddd;
  text-decoration: none;
}
.Form {
  position: relative;
  padding-bottom: 10rem;
  --transition-duration: 0.3s;
  overflow-y: hidden;
}
.Form__body > * + * {
  margin-top: 3.6rem;
}
.Form__foot {
  position: absolute;
  width: 100%;
  top: calc(100% - 3.2rem);
  margin-top: -3.2rem;
  overflow: visible;
}
.Form__anchor {
  position: absolute;
  top: -10rem;
}
.Form__splah {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2.4rem;
  text-align: center;
  opacity: 0;
  transition: opacity var(--transition-duration);
  pointer-events: none;
}
.Form__splah.active {
  opacity: 1;
}
.Form .btn-submit {
  margin: 0 auto;
  position: relative;
  transition: all var(--transition-duration) cubic-bezier(0.68, -0.55, 0.265, 1.55);
  --loader-duration: 1.75s;
}
.Form .btn-submit svg,
.Form .btn-submit span {
  transition: all var(--transition-duration) ease-in-out;
}
.Form .btn-submit svg {
  animation: rotate calc(var(--loader-duration) * 7 / 3) linear infinite;
  position: absolute;
  height: 70%;
  opacity: 0;
}
.Form .btn-submit svg circle {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash var(--loader-duration) ease-in-out infinite;
  stroke-linecap: round;
  stroke: #fff;
}
.Form.submitting .btn-submit, .Form.submitted .btn-submit {
  width: 6.4rem;
  border-radius: 9in;
}
.Form.submitting .btn-submit svg, .Form.submitted .btn-submit svg {
  opacity: 1;
}
.Form.submitting .btn-submit span, .Form.submitted .btn-submit span {
  opacity: 0;
}
.Form.submitted .btn-submit {
  opacity: 0;
}
.Footer {
  background: var(--clr-dark);
  color: var(--clr-light);
  font-size: 1.4rem;
  line-height: 2.5rem;
  text-align: center;
}
.Footer__head {
  padding-bottom: 4.8rem;
}
.Footer__info {
  padding: 2.4rem 0;
  border-top: 1px solid;
  border-bottom: 1px solid;
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;
}
.Footer__foot {
  margin-top: 4.8rem;
}

.card {
  display: flex;
  gap: 1.2rem;
  text-align: left;
  white-space: nowrap;
}
.card h4 {
  font-size: 1.8rem;
}
.card img {
  height: 8rem;
}
.card span {
  display: inline-flex;
  width: 1.5em;
}
/*# sourceMappingURL=index.5fc206f0.css.map */
