.LocationFloor {
  background: var(--clr-dark);
  color: var(--clr-light);
  isolation: isolate;
  padding-top: 70vw;
  position: relative;

  &::before {
    -webkit-transform: translate3d(0, 0, 0);
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    z-index: -1;

    background: linear-gradient(rgba(#141414, 0) 50vw, #141414 120vw, #2f2f2f);
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 120vw;
    object-fit: cover;
    z-index: -2;
    overflow: hidden;

    // display:none;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .btn {
    --clr: var(--clr-dark);
    --bg: var(--clr-light);
    --bg-hover: var(--clr-light);
  }

  .Route {
    margin-top: 4.8rem;
    margin-bottom: 4.8rem;
  }
}
