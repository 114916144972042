// @import url("//db.onlinewebfonts.com/c/b94dc8329d2352f4d5cdeccfc0209cdc?family=ITC+Souvenir+Monospaced");

html,
body {
}

html {
  font-size: 62.5%;
  // font-size: clamp(31.25%, 12 * (100vw / 768), 68.75%);
  scroll-padding-top: 15rem;
}

body {
  height: auto;
  min-height: 100%;

  overflow-x: hidden;

  font-family: var(--ff);
  font-size: var(--fs);
  line-height: var(--lh);
  font-weight: var(--fw);

  &.no-scroll,
  &:not(.loaded) {
    overflow: hidden;
  }
}

body,
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  background: var(--bg);
}

a {
  color: inherit;
  text-decoration: none;

  &:hover,
  &:focus-visible,
  &[aria-current] {
    text-decoration: underline;
  }
}

b,
strong {
  font-weight: var(--fw-bold);
}

h1,
h2,
h3,
h4,
legend {
  font-family: var(--ff-accent);
}

h1 {
  font-size: 6rem;
  line-height: 6rem;
}

h2 {
  font-size: 6rem;
  line-height: 6rem;
}

h3,
legend {
  font-size: 2.8rem;
  line-height: 3.3rem;
}

h4 {
  font-size: 2rem;
  line-height: 2.4rem;
}

.btn {
  --bg-hover: #161616;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;

  font-family: var(--ff-accent);
  font-size: 2.4rem;
  line-height: 2.2rem;
  padding: 2rem 2.4rem 2.2rem;

  color: var(--clr, #fff);
  background: var(--bg, #333);
  border: 0;
  transition: color var(--duration-hover),
    background-color var(--duration-hover);

  cursor: pointer;

  &:not([disabled]):hover {
    --bg: var(--bg-hover);
    text-decoration: none;
  }

  &--alt {
    border: 1px solid;
    background: var(--clr, #fff);
    color: var(--bg, #333);
  }

  &[disabled] {
    opacity: 0.3;
    cursor: default;
  }
}

legend {
  margin-bottom: var(2.4rem);
}

input,
textarea {
  padding: 0.8rem 0;
  border: 0;
  background: none;
  border-bottom: 0.2rem solid #bdbdbd;
  border-radius: 0;
  width: 100%;

  font-family: var(--ff-accent);
  font-size: 1.8rem;
  line-height: 2.8rem;

  transition: border-color 0.3s;

  &:focus {
    box-shadow: none;
    outline: none;
    border-color: #141414;
  }
}

textarea {
  resize: none;
  height: 10.2rem;
  transition: height 0.3s;

  &:placeholder-shown:not(:focus) {
    // background: #f00;
    height: 4.6rem;
  }
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bdbdbd;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bdbdbd;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bdbdbd;
}

.flow,
form,
fieldset {
  > * + * {
    margin-top: var(--flow-space, 2.4rem);
  }
}

form {
  --flow-space: 3.6rem;
}

fieldset {
  --flow-space: 1.2rem;
}

pre {
  font-family: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console",
    "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono",
    "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier,
    monospace;
  white-space: pre-wrap;
}
